<template>
    <div>
        <TerminalUpdate type="update"></TerminalUpdate>
    </div>
</template>

<script>
    import TerminalUpdate from '@/components/terminalUpdate/Index';
    export default {
        components:{
            TerminalUpdate
        },
        data() {
            return {
            }
        },
        mounted() {
        },
        
    };
</script>

<style scoped>

</style>
